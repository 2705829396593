<template>
  <b-container fluid>
       <b-row>
            <template>
                <b-row>
                    <b-col md="12">
                          <table class="table table-sm table-borderless">
                            <tr>
                                <th style="width: 15%">{{ $t('teaGardenBtriService.schedule_id')}}</th>
                                <td style="width: 30%"> : {{ items.schedule_id }}</td>
                                <th style="width: 15%">{{ $t('globalTrans.fiscal_year')}}</th>
                                <td style="width: 30%"> : {{ getFiscalYear(items.fiscal_year_id) }}</td>
                            </tr>
                            <tr>
                                <th style="width: 15%">{{ $t('teaGardenBtriService.event_type')}}</th>
                                <td style="width: 30%"> : {{ getEventType(items.event_type) }}</td>
                                <th v-if="items.event_type === 1" style="width: 15%">{{$t('teaGardenBtriService.training_name')}}</th>
                                <td v-if="items.event_type === 1" style="width: 30%"> : {{ currentLocale === 'bn' ? items.training_name_bn : items.training_name_en }} </td>
                                <th v-if="items.event_type === 2" style="width: 20%">{{$t('teaGardenBtriService.workshop_name')}}</th>
                                <td v-if="items.event_type === 2" style="width: 30%"> : {{  currentLocale === 'bn' ? items.workshop_title_bn : items.workshop_title_en }} </td>
                                <th v-if="items.event_type === 3" style="width: 15%">{{$t('teaGardenBtriService.course_name')}}</th>
                                <td v-if="items.event_type === 3" style="width: 30%"> : {{  currentLocale === 'bn' ? items.course_name_bn : items.course_name_en }} </td>
                            </tr>
                            <tr>
                                <th style="width: 15%">{{ $t('globalTrans.date_from')}}</th>
                                <td style="width: 30%"> : {{ items.date_from | dateFormat }} </td>
                                <th style="width: 15%">{{ $t('globalTrans.date_to')}}</th>
                                <td style="width: 30%"> : {{ items.date_to | dateFormat }}</td>
                            </tr>
                            <tr>
                                <th v-if="items.time_from" style="width: 15%">{{ $t('globalTrans.time_from')}}</th>
                                <td v-if="items.time_from" style="width: 30%"> : {{ items.time_from | time12Formate }} </td>
                                <th v-if="items.time_to" style="width: 15%">{{ $t('globalTrans.time_to')}}</th>
                                <td v-if="items.time_to" style="width: 30%"> : {{ items.time_to | time12Formate }}</td>
                            </tr>
                            <tr>
                                <th style="width: 15%">{{ $t('teaGardenBtriService.place')}}</th>
                                <td style="width: 30%"> : {{  currentLocale === 'bn' ? items.place_bn : items.place_en }} </td>
                                <!-- <th style="width: 15%">{{ $t('globalTrans.status')}}</th>
                                <td style="width: 30%"> : {{ getStatusType(items.status) }} </td> -->
                            </tr>
                            <tr>
                                <th v-if="items.description_en" style="width: 15%">{{ $t('globalTrans.description')}}</th>
                                <td v-if="items.description_en" style="width: 30%"> : {{  currentLocale === 'bn' ? items.description_bn : items.description_en }} </td>
                            </tr>
                        </table>
                        <table class="table" v-if="items.event_type == 2 && items.details.length > 0">
                            <b-thead>
                            <tr>
                                <th class="text-left" v-if="items.details" style="width: 30%">{{ $t('teaGardenBtriService.event_name')}}</th>
                                <th class="text-center" v-if="items.details" style="width: 30%">{{ $t('globalTrans.start_time')}}</th>
                            </tr>
                            </b-thead>
                            <b-tbody>
                            <tr v-for="(item, index) in items.details" :key="index">
                                <td class="text-left" style="width: 30%">{{ $n(index + 1 ) }}. {{ currentLocale == 'bn' ? item.event_name_bn : item.event_name_en }}</td>
                                <td class="text-center" style="width: 30%">{{ item.start_time | time12Formate }}</td>
                            </tr>
                            <b-tr v-if="items.details.length <= 0">
                            <td colspan="12" class="text-center">
                                <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
                            </td>
                            </b-tr>
                            </b-tbody>
                        </table>
                    </b-col>
                </b-row>
            </template>
        </b-row>
  </b-container>
</template>
<script>
import { eBizServiceBaseUrl } from '@/config/api_config'
export default {
    props: ['items'],
    components: {
    },
    created () {
        if (this.items) {
            const tmp = this.getDetailsData()
            this.formData = tmp
        }
    },
    mounted () {
        // core.index()
    },
    data () {
        return {
            budgetEntryData: [],
            eBizServiceBaseUrl: eBizServiceBaseUrl,
            formData: {}
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        },
        eventTypeList: function () {
            const list = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Training' : 'প্রশিক্ষণ' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Workshop' : 'কর্মশালা' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Course' : 'কোর্স' }
            ]
            return list
        },
        statusList: function () {
            const list = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Pending' : 'অমীমাংসিত' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Circulated' : 'প্রচারিত' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Nominee Submitted' : 'নমিনি জমা দেওয়া হয়েছে' },
                { value: 4, text: this.$i18n.locale === 'en' ? 'Nominee Approved' : 'নমিনি অনুমোদিত' },
                { value: 5, text: this.$i18n.locale === 'en' ? 'Certified' : 'প্রত্যয়িত' }
            ]
            return list
        }
    },
    methods: {
        getFiscalYear (yearId) {
            const cateObj = this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1).find(item => item.value === yearId)
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            }
        },
        getDetailsData () {
            const itemId = this.items.id
            const tmpData = this.$store.state.list.find(item => item.id === parseInt(itemId))
            return JSON.parse(JSON.stringify(tmpData))
        },
        getEventType (sta) {
            const reqStatusObj = this.eventTypeList.find(tlaw => tlaw.value === parseInt(sta))
            return reqStatusObj.text
        },
        getStatusType (sta) {
            const reqStatusObj = this.statusList.find(tlaw => tlaw.value === parseInt(sta))
            return reqStatusObj.text
        }
    }
}

</script>
